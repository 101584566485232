import React, { useEffect, useState } from "react";
const axios = require('axios').default;

function Inquiery() {
  const [contact_details,setContact_details] = useState({
    first_name : "",
    last_name : "",
    contact_number : "",
    email_address : "",
    contact_subject : ""
  });

  const [loader,setloader] = useState("");
  const [sentMessage,setsentMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");
  const [showErrorMessage,setShowErrorMessage] = useState("");
  
  const inputEvent = (e:React.ChangeEvent<HTMLInputElement>) => {
    
    var name_data :String  = e.target.name;
    if(name_data.length > 0) {
      
      setShowErrorMessage("");
      setErrorMessage("");

      if(name_data === "first_name") {

        setContact_details(contact_details => ({
          ...contact_details,
          first_name : e.target.value,
        }));

      } else if(name_data === "last_name") {
        
        setContact_details(contact_details => ({
          ...contact_details,
          last_name : e.target.value,
        }));

      } else if(name_data === "contact_number") {

        setContact_details(contact_details => ({
          ...contact_details,
          contact_number : e.target.value,
        }));

      } else if(name_data === "email_address") {

        setContact_details(contact_details => ({
          ...contact_details,
          email_address : e.target.value,
        }));
        
      } else if(name_data === "contact_subject") {

        setContact_details(contact_details => ({
          ...contact_details,
          contact_subject : e.target.value,
        }));
      }
    } else {
      setShowErrorMessage("d-block");
      setErrorMessage("All field must be required for connect with us..!");
    }
  }

  const validateEmail = (email_id:String) => {
    return String(email_id)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  const validateContact = (contact_number:String) => {
    return String(contact_number)
      .toLowerCase()
      .match(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      );
  };

  const onConactSubmit  = (event:React.FormEvent<HTMLFormElement>) => {
    
    event.preventDefault();
    if(contact_details.first_name.length === 0) {
      setShowErrorMessage("d-block");
      setErrorMessage("Please fill your first name to contact with us..!");
      return false;
    }

    if(contact_details.last_name.length === 0) {
      setShowErrorMessage("d-block");
      setErrorMessage("Please fill your last name to contact with us..!");
      return false;
    }

    if(contact_details.contact_number.length === 0) {
      setShowErrorMessage("d-block");
      setErrorMessage("Please fill your Contact number to contact with us..!");
      return false;
    }

    if(!validateContact(contact_details.contact_number)) {
      setShowErrorMessage("d-block");
      setErrorMessage("Please fill valid Contact number..!");
      return false;
    }

    if(contact_details.email_address.length === 0) {
      setShowErrorMessage("d-block");
      setErrorMessage("Please fill your Email to contact with us..!");
      return false;
    }

    if(!validateEmail(contact_details.email_address)) {
      setShowErrorMessage("d-block");
      setErrorMessage("Please write email in correct format..!");
      return false;
    }

    if(contact_details.contact_subject.length === 0) {
      setShowErrorMessage("d-block");
      setErrorMessage("Please write your purpose to Contact with us..!");
      return false;
    }

    setShowErrorMessage("");
    setErrorMessage("");
    
    let data = {
      "fist_name" : contact_details.first_name,
      "last_name" : contact_details.last_name,
      "email" : contact_details.email_address,
      "contact" : contact_details.contact_number,
      "message" : contact_details.contact_subject,
      "type" : "Inquery"
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    setloader("d-block");
    axios.post('https://api.ajanaimpex.com/send_contact_email',data, axiosConfig)
    .then(function (response:Response) {
      setloader("");
      setsentMessage("d-block");
      setContact_details(contact_details => ({
        first_name : "",
        last_name : "",
        contact_number : "",
        email_address : "",
        contact_subject : ""
      }));
    })
    .catch(function (error:Error) {
      setloader("");
      setsentMessage("d-block");
      setContact_details(contact_details => ({
        first_name : "",
        last_name : "",
        contact_number : "",
        email_address : "",
        contact_subject : ""
      }));
    });
  } 
  
  return (
    <>
        {/* ======= Contact Section ======= */}
    <section id="contact" className="contact">
      <div className="container">

        <div className="section-title">
          <h3><u>Quick Inquery</u></h3>
        </div>

        <div className="row">

          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="info">
              <div className="row" style={{marginTop:"3%"}}>
                <div className="address col-lg-4">
                  <a href="https://goo.gl/maps/D223no8W3NtZ1CqP6" target="_blank">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Office No:202, OM Complex, Bhaktinagar Station Circle, Rajkot, Gujarat, India - 360002</p>
                  </a>
                </div>
                <div className="email col-lg-4">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <a href="mailto:support@ajanaimpex.com">
                <p>support@ajanaimpex.com</p>
                </a>
              </div>
              <div className="phone col-lg-4">
                <a href="tel:+91 95125 09292">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 95125 09292</p>
                </a>
              </div>
              </div>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder="0" style={{marginTop:"3.5%",border: "0", width: "100%", height: "290px"}} allowFullScreen></iframe> */}
            </div>

          </div>

          <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch" style={{paddingTop:"5%"}}>
            
            <form role="form" className="php-email-form" onSubmit={onConactSubmit}>  
              <div className="row">
                  <div className="col-md-6 form-group">
                  <label htmlFor="name">&nbsp;First Name: </label>
                  <p style={{"marginBottom":"-1px"}}></p>
                    <input type="text" name="first_name" className="form-control" placeholder="First Name" autoComplete="off" onChange={inputEvent} value={contact_details.first_name}/>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                  <label htmlFor="name">&nbsp;Last Name: </label>
                  <p style={{"marginBottom":"-1px"}}></p>
                    <input type="text" className="form-control" name="last_name"  placeholder="Last name" autoComplete="off" onChange={inputEvent} value={contact_details.last_name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group mt-3">
                  <label htmlFor="name">&nbsp;Contact Number: </label>
                  <p style={{"marginBottom":"-1px"}}></p>
                    <input type="text" name="contact_number" className="form-control" placeholder="Your Contact Number" autoComplete="off" onChange={inputEvent} value={contact_details.contact_number}/>
                  </div>
                  <div className="col-md-6 form-group mt-3">
                  <label htmlFor="name">&nbsp;Email Address: </label>
                  <p style={{"marginBottom":"-1px"}}></p>
                    <input type="text" className="form-control" name="email_address" value={contact_details.email_address} placeholder="Your Email Address" autoComplete="off" onChange={inputEvent}/>
                  </div>
                </div>
                <div className="form-group mt-3">
                <label htmlFor="name">&nbsp;Inquery Purpose: </label>
                  <p style={{"marginBottom":"-1px"}}></p>
                  <input type="text" className="form-control" name="contact_subject" value={contact_details.contact_subject} placeholder="Please Write Purpose Of Your Inquery.." autoComplete="off" onChange={inputEvent}/>
                </div>
                {/* <div className="form-group mt-3">
                  <textarea className="form-control" name="message" placeholder="Message" autoComplete="off" onChange={inputEvent}></textarea>
                </div> */}
                <div className="my-3">
                  <div className={`loading ${loader}`}>Loading..</div>
                  <div className={`error-message ${showErrorMessage}`}>{errorMessage}</div>
                  <div className={`sent-message ${sentMessage}`}>
                    Thank you for your inquery..! Our Team will connect to you soon..
                  </div> 
                </div>
                <div className="text-center">
                  <button type="submit">Quick Inquery</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </section>{/* End Contact Section */}
    </>
  );
}
export default Inquiery;