import React from "react";

function RedChilli() {
  return (
    <>
  <main id="main">


<section id="breadcrumbs" className="breadcrumbs">
  <div className="container">

    <div className="d-flex justify-content-between align-items-center">
      <h2>Red Chilli</h2>
      <ol>
        <li><a href="/">Home</a></li>
        <li>Products</li>
        <li>Red Chilli</li>
      </ol>
    </div>

  </div>
</section>

<section id="portfolio-details" className="portfolio-details">
  <div className="container">

    <div className="row gy-4">

      <div className="col-lg-6">
        <div className="portfolio-details-slider swiper">
          <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">

              <img src="/Products/red-chilli.jpg" alt=""
              style={{
                height: "475px",
                width: "450px;",
                display: "block",
                margin: "auto",
                marginTop:"30px"
              }}
              className="product_image"
              />
          </div>
        </div>
      </div>

      <div className="col-lg-6">
      <div className="portfolio-description">
          <h2>Red Chilli</h2>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Red chillies or hot pepper is taken as one of the most valuable crops in India available throughout 
                the country and suitable to use with different food items.
          </p>
        </div>
        <div className="portfolio-info">
          <h3>Red Chilli</h3>
          <ul>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Red chillies are small in size and popular for their strong acidic flavor and dark colour.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>As you can see, chillies are used worldwide in making different medicines and beverages too.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Red chillies are also used as color agent to give natural color to different spices and food items.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Red chillies are small in size and popular for their strong acidic flavor and dark color.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Product URL</strong>: <a href="https://www.ajanaimpex.com/Red-Chilli">www.ajanaimpex.com/Red-Chilli</a></li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</section>

</main>
</>
  );
}

export default RedChilli;