import React from "react";

function GroundnutPeanut() {
  return (
    <>
  <main id="main">


<section id="breadcrumbs" className="breadcrumbs">
  <div className="container">

    <div className="d-flex justify-content-between align-items-center">
      <h2>Groundnut or Peanut</h2>
      <ol>
        <li><a href="/">Home</a></li>
        <li>Products</li>
        <li>Groundnut or Peanut</li>
      </ol>
    </div>

  </div>
</section>

<section id="portfolio-details" className="portfolio-details">
  <div className="container">

    <div className="row gy-4">

      <div className="col-lg-6">
        <div className="portfolio-details-slider swiper">
          <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">

              <img src="/Products/Groundnut-or-Penut.jpg" alt=""
              style={{
                height: "475px",
                width: "450px;",
                display: "block",
                margin: "auto",
                marginTop:"30px"
              }}
              className="product_image"
              />
          </div>
        </div>
      </div>

      <div className="col-lg-6">
      <div className="portfolio-description">
          <h2>Groundnut or Peanut</h2>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Peanut or ground nut is sixth most popular herb on the earth. It has 50 percent oil content and 30 percent protein content. It is taken as rich source of minerals, dietary fiber, and vitamins.
          </p>
        </div>
        <div className="portfolio-info">
          <h3>Groundnut or Peanut</h3>
          <ul>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Peanut can be used as butter with bread or it can be mixed with maize to use along with drink.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Gujarat shares major area for growing peanuts when compared to whole country.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>All essential nutrients necessary to maintain body health are available inside peanuts so it should be consumed on regular basis.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Peanut butters are very popular among the country and overseas too. It is speeded over bread and taken usually during lunch time.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Product URL</strong>: <a href="https://www.ajanaimpex.com/Groundnut-or-Peanut">www.ajanaimpex.com/Groundnut-or-Peanut</a></li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</section>

</main>
</>
  );
}

export default GroundnutPeanut;