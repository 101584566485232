import React from "react";
import Portfolio from "../Pages/Portfolio";
import AboutCompany from "../Pages/AboutCompany";
import ProductsGallary from "../Pages/ProductsGallary";
import HappyClients from "../Pages/HappyClients";

function Home() {
  return (
    <>
      {/* <Services /> */}
      <AboutCompany />
      <ProductsGallary />
      {/* <OurProducts /> */}
      <HappyClients />
      {/* <WhatMakesDifferent /> */}
      {/* <ContactUsHome /> */}

      {/* <Portfolio></Portfolio> */}
      <section className="banner" style={{ marginTop: "5%" }}>
        {/* <img src="/assets/img/bg.jpg" /> */}
        <div
          className="content"
          style={{
            backgroundImage: "url('/assets/img/bg.jpg')",
            backgroundSize: "100% 100%",
          }}
        >
          <p className="desktop-title">
            <span className="number-of-years">12</span>
            <span className="number-of-experiance">
              Years experience
              <br /> makes us the best.
            </span>
          </p>
          {/* <p className="mobile-title">
                <span className="mobile-title-text">demo container tiitle for mobile.</span>
            </p> */}
          <p className="container-description container-description-1">
            {/* Being one of the main manufacturers, suppliers and exporters of this
            immaculate scope of Raw cotton, Animal feed meal, Sesame seeds,
            Cumin seeds, Wheat flour, Maida flour, Groundnuts or peanuts, Red
            chilli, Yellow Maize(Corn), Indian Green Millet And SoyaBean since
            2011, Ajana Impex is known to be among the market chiefs. */}
            Being one of the main manufacturers, suppliers and exporters of this
            immaculate scope of Groundnuts or peanuts, Rice, Sesame seeds, Cumin
            seeds, Coriander seeds, Yellow Maize(Corn),Green Millet, Checkpea,
            Mung beans, Mustard Seed and Soya Bean since 2011, Ajana Impex is
            known to be among the market chiefs.
          </p>
        </div>
      </section>
    </>
  );
}
export default Home;
