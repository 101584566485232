import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <>
      <section
        className="about-company"
        style={{
          backgroundColor: "rgba(245, 245, 245, 1)",
          //paddingTop: "5%",
          paddingBottom: "5%",
        }}
      >
        <div
          className="container"
          style={{
            marginTop: "-3%",
          }}
        >
          <div
            class="row"
            style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}
          >
            <div
              className="col-md-6"
              style={{ borderRight: "1px solid rgba(194, 194, 194, 1)" }}
            >
              <div style={{ textAlign: "left" }}>
                <h1>
                  <b style={{ color: "#5cb874", fontSize: "135%" }}>
                    Who we are?
                  </b>
                </h1>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p style={{ fontSize: "120%" }}>
                  We are a reliable supplier of our high quality products in
                  India and other countries.
                </p>
                <p style={{ fontSize: "120%" }}>
                  With more than 12 years of experience, we are the pioneer of
                  animal feed products in the industry.
                </p>
              </div>
              <div style={{ marginTop: "5%" }} onClick={executeScroll}>
                <p style={{ fontSize: "120%" }}>
                  <span
                    style={{
                      color: "#5cb874",
                      fontSize: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <b>Vision&nbsp;&nbsp;</b>
                    <i class="bi bi-arrow-right"></i>
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    style={{
                      color: "#5cb874",
                      fontSize: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <b>Mission&nbsp;&nbsp;</b>
                    <i class="bi bi-arrow-right"></i>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ marginTop: "2%" }}>
                <p style={{ maxWidth: "95%", marginLeft: "3%" }}>
                  Welcome to the world of "Ajana Impex Private Limited," a
                  certified MSME company started Jan 2012 in Rajkot, Gujrat,
                  India. With a positive approach to motivate the attention of
                  the farmers and feed manufacturers toward the best Quality Raw
                  cotton,Groundnuts or peanuts, Rice, Sesame seeds, Cumin seeds,
                  Coriander seeds, Yellow Maize(Corn),Green Millet, Checkpea,
                  Mung beans, Mustard Seed, Soya Bean and other products. The
                  company's beginning was initiated by Mr.Mahipat Ajana, the
                  company's CEO.
                </p>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p style={{ maxWidth: "95%", marginLeft: "3%" }}>
                  We are a trusted manufacturer & supplier of Sterilized Quality
                  ,Groundnuts or peanuts, Rice, Sesame seeds, Cumin seeds,
                  Coriander seeds, Yellow Maize(Corn),Green Millet, Checkpea,
                  Mung beans, Mustard Seed, Soya Bean and other products in
                  industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="about-company"
        style={
          {
            //paddingTop: "5%",
          }
        }
      >
        <div
          className="container"
          style={{
            marginTop: "-3%",
            paddingBottom: "5%",
          }}
        >
          <div class="row">
            <div className="col-md-3">
              <div style={{ textAlign: "center" }}>
                <img src="/Products/vivek/quality.png" height={55}></img>
              </div>
              <div style={{ textAlign: "center", marginTop: "3%" }}>
                <h6>
                  <b>Quality Assured Products</b>
                </h6>
              </div>
              <div style={{ textAlign: "center" }}>
                <p>
                  We believe in delivering premium quality products to everyone
                  and will continue to do it.
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div style={{ textAlign: "center" }}>
                <img src="/Products/vivek/supply.png" height={55}></img>
              </div>
              <div style={{ textAlign: "center", marginTop: "3%" }}>
                <h6>
                  <b>Strong Supply Chain</b>
                </h6>
              </div>
              <div style={{ textAlign: "center" }}>
                <p>
                  Our robust supply chain is so effective that it can deliver to
                  25+ countries.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ textAlign: "center" }}>
                <img
                  src="/Products/vivek/Industry-Leading Prices.png"
                  height={55}
                ></img>
              </div>
              <div style={{ textAlign: "center", marginTop: "3%" }}>
                <h6>
                  <b>Industry-Leading Prices</b>
                </h6>
              </div>
              <div style={{ textAlign: "center" }}>
                <p>
                  We offer prices like no one in the industry. Our products are
                  very affordable.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ textAlign: "center" }}>
                <img
                  src="/Products/vivek/customerfeedback.png"
                  height={55}
                ></img>
              </div>
              <div style={{ textAlign: "center", marginTop: "3%" }}>
                <h6>
                  <b>Customer Feedback</b>
                </h6>
              </div>
              <div style={{ textAlign: "center" }}>
                <p>
                  We, as a company, come strong when it comes to customer
                  feedback and customer relations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={myRef}
        className="about-company"
        style={{
          backgroundColor: "rgba(245, 245, 245, 1)",
          //paddingTop: "5%",
          paddingBottom: "5%",
        }}
      >
        <div
          className="container"
          style={{
            marginTop: "-3%",
          }}
        >
          <div
            class="row"
            style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}
          >
            <div className="col-lg-6" style={{ paddingTop: "50px" }}>
              <div className="portfolio-details-slider swiper">
                <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                  <img
                    src="/Products/misson-vision.png"
                    alt=""
                    style={{
                      height: "325px",
                      width: "325px;",
                      margin: "auto",
                    }}
                    className="product_image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ marginTop: "2%", paddingLeft: "5%" }}>
                <h1>
                  <b>Our Vision</b>
                </h1>
                <p>
                  Vision to promote world-class dry render quality Raw
                  cotton,Groundnuts or peanuts, Rice, Sesame seeds, Cumin seeds,
                  Coriander seeds, Yellow Maize(Corn),Green Millet, Checkpea,
                  Mung beans, Mustard Seed, Soya Bean and other products
                  supplements preparation.
                </p>
                <h1>
                  <b>Our Mission</b>
                </h1>
                <p>
                  Our positive approach is to motivate the attention of the
                  farmers and feed manufacturers toward the best Quality,
                  Sterilized,Groundnuts or peanuts, Rice, Sesame seeds, Cumin
                  seeds, Coriander seeds, Yellow Maize(Corn),Green Millet,
                  Checkpea, Mung beans, Mustard Seed, Soya Bean and other
                  products Supplements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
