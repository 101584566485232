import React from "react";

function MustardSeed() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Mustard Seeds</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Mustard Seeds</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/Mustard-Seeds.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Mustard Seeds</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mustard is a condiment
                    primarily made from the seeds of a mustard plant. The
                    Mustard Seeds exporter in India exports tonnes of mustard
                    products from India. Due to the various uses and benefits of
                    Mustard, the Mustard Seeds export from India has been on a
                    rise. This is considered as a good opportunity for the
                    Indian Mustard Seeds exporter to expand his business and
                    enter the international market. The Mustard Seeds export
                    from India includes various varieties of mustard.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ajana Impex Pvt. Ltd.
                    is one of the leading Mustard Seeds importers from India. We
                    at we have extensive experience in the food export industry.
                    After understanding the client’s requirements, we help the
                    client in every way possible for the export of Mustard
                    Seeds. Right from finding clients until the final delivery
                    of the products, we will support you with everything. If you
                    are looking for Mustard Seeds export from India, Contact us
                    now!
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-info">
                  <h3>Ingredients & Specifications</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Nutrients </strong>: Calories 66 kcal,Total
                      Carbohydrates - 5 grams, Fiber 3.3 grams
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Minerals </strong>: Calcium, Iron, Magnesium,
                      Cobalamin
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Vitamins </strong>: Vitamin A, Vitamin C, Vitamin
                      D, Vitamin B-6
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Mustard-Seed">
                        www.ajanaimpex.com/Mustard-Seeds
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MustardSeed;
