import React from "react";

function WheatFlour() {
  return (
    <>
  <main id="main">


<section id="breadcrumbs" className="breadcrumbs">
  <div className="container">

    <div className="d-flex justify-content-between align-items-center">
      <h2>Wheat Flour (Chakki Atta)</h2>
      <ol>
        <li><a href="/">Home</a></li>
        <li>Products</li>
        <li>Wheat Flour (Chakki Atta)</li>
      </ol>
    </div>

  </div>
</section>

<section id="portfolio-details" className="portfolio-details">
  <div className="container">

    <div className="row gy-4">

      <div className="col-lg-6">
        <div className="portfolio-details-slider swiper">
          <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">

              <img src="/Products/Wheat-Flour.jpg" alt=""
              style={{
                height: "450px",
                width: "450px;",
                display: "block",
                margin: "auto",
                marginTop: "25px"
              }}
              className="product_image"
              />
          </div>
        </div>
      </div>

      <div className="col-lg-6">
      <div className="portfolio-description">
          <h2>Wheat Flour (Chakki Atta)</h2>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Wheat flour is a major flour product used by Indians 
                locally as well as internationally & It can be also used for making a wide range of foods like chapattis,
                 puris ,paratha,pasta, and breads.
          </p>
        </div>
        <div className="portfolio-info">
          <h3>Wheat Flour (Chakki Atta)</h3>
          <ul>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Wheat flour is popularly known as Atta.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Good nutritional value.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Only pure ingredients are used.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Have a good absorption rate.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Easy to dough.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Product URL</strong>: <a href="https://www.ajanaimpex.com/Wheat-Flour">www.ajanaimpex.com/Wheat-Flour</a></li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</section>

</main>
</>
  );
}

export default WheatFlour;