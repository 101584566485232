import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import NavigationBar from "./NavigationBar";

function Footer() {
  const navigate = useNavigate();

  return (
    <>
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <div className="footer-logo">
                {/* 
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }} */}
                <a onClick={() => navigate("/")} className="coursor-pointer">
                  <img
                    src="/assets/img/slide/Logo.png"
                    style={{
                      maxHeight: "120px",
                      maxWidth: "300px",
                      marginLeft: "-10px",
                    }}
                    className="img-fluid footer_logo"
                  />
                </a>
              </div>
              <p>
                Being one of the main manufacturers, suppliers and exporters of
                this immaculate scope of Groundnuts or peanuts, Rice, Sesame
                seeds, Cumin seeds, Coriander seeds, Yellow Maize(Corn),Green
                Millet, Checkpea, Mung beans, Mustard Seed and Soya Bean since
                2011, Ajana Impex is known to be among the market chiefs.
              </p>
              <div className="social-links">
                <a href="mailto:info@ajanaimpex.com" className="twitter">
                  <i className="bi bi-envelope-fill"></i>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=+919512509292&text=AjanaImpex"
                  target="_blank"
                  className="google-plus"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="bi bi-whatsapp"></i>
                </a>
                <a
                  href="tel:+91 95125 09292"
                  className="google-plus"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="bi bi-phone-fill phone-icon"></i>
                </a>
                <a
                  href="https://www.facebook.com/AJANA-IMPEX-PVT-LTD-112059888224109/"
                  target="_blank"
                  className="facebook"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="bx bxl-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/ajana_impex_group/"
                  className="instagram"
                  target="_blank"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="bx bxl-instagram"></i>
                </a>
                <a
                  href="https://goo.gl/maps/D223no8W3NtZ1CqP6"
                  className="instagram"
                  target="_blank"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="bi bi-geo-alt"></i>
                </a>
                {/*<a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a> */}
              </div>
            </div>

            <div
              className="col-md-3"
              style={{
                marginTop: "15px",
                textAlign: "left",
              }}
            >
              <h2 style={{ color: "#5cb874" }}>Quick Links</h2>
              <p
                style={{ fontSize: "18px", marginTop: "0px" }}
                className="coursor-pointer"
              >
                <a onClick={() => navigate("")}>Home</a>
              </p>
              <p
                style={{ fontSize: "18px", marginTop: "-25px" }}
                className="coursor-pointer"
              >
                <a onClick={() => navigate("/Products")}>Products</a>
              </p>
              <p
                style={{ fontSize: "18px", marginTop: "-25px" }}
                className="coursor-pointer"
              >
                <a onClick={() => navigate("/globalExports")}>Global Exports</a>
              </p>
              <p
                style={{ fontSize: "18px", marginTop: "-25px" }}
                className="coursor-pointer"
              >
                <a onClick={() => navigate("/Contact")}>Contact</a>
              </p>
              <p
                style={{ fontSize: "18px", marginTop: "-25px" }}
                className="coursor-pointer"
              >
                <a onClick={() => navigate("/Inquiery")}>Quick Inquiery</a>
              </p>
            </div>
            <div
              className="col-md-5"
              style={{
                marginTop: "15px",
                textAlign: "left",
              }}
            >
              <h2 style={{ color: "#5cb874" }}>Our Popular Products</h2>
              <div className="row" style={{ marginTop: "35px" }}>
                {/* <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Raw-Cotton")}>
                      <span className="coursor-pointer">Raw cotton</span>
                    </a>
                  </p>
                </div> */}
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Groundnut-or-Peanut")}>
                      <span className="coursor-pointer">
                        Groundnuts or peanuts
                      </span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Rice")}>
                      <span className="coursor-pointer">Rice</span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Sesame-Seeds")}>
                      <span className="coursor-pointer">Sesame seed</span>
                    </a>
                  </p>
                </div>

                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Cumin-Seeds")}>
                      <span className="coursor-pointer">Cumin seed</span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Coriander-Seeds")}>
                      <span className="coursor-pointer">Coriander seeds</span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Yellow-Maize")}>
                      <span className="coursor-pointer">
                        Yellow Maize (Corn)
                      </span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Green-Millet")}>
                      <span className="coursor-pointer">Green Millet</span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Checkpea")}>
                      <span className="coursor-pointer">Checkpea</span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Mung-beans")}>
                      <span className="coursor-pointer">Mung beans</span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Mustard-Seed")}>
                      <span className="coursor-pointer">Mustard Seed</span>
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "18px", marginTop: "-25px" }}>
                    <a onClick={() => navigate("/Soya-Bean")}>
                      <span className="coursor-pointer">Soya Bean</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>AjanaImpex</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
}
export default Footer;
