import React from "react";

const HappyClients = () => {
  const data = [
    {
      number: "15",
      name: "High Quality Products",
    },
    {
      number: "500",
      name: "Happy Clients",
    },
    {
      number: "25",
      name: "Country Covered",
    },
    {
      number: "12",
      name: "Years Excellence",
    },
  ];
  return (
    <section className="happy-clients">
      <div className="container">
        <div className="happy-client-content">
          <div className="row">
            {data.map((items) => (
              <div className="col-lg-3">
                <div className="single-happy-client my-lg-0 my-3">
                  <h5>{items.number} +</h5>
                  <p>{items.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HappyClients;
