import React from "react";

function Checkpea() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Checkpea</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Checkpea</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/Checkpea.jfif"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Checkpea</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chickpeas Are Valued For
                    Its High Fiber And Protein Content. Part Of Legume Family,
                    Chickpea Is One Of The Earliest Cultivated Pulses. Chickpeas
                    Are Popular As Garbanzo Bean In Spain, Ceci Beans In Italy
                    And As Bengal Gram In India.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ajana Impex Pvt. Ltd.
                    Deals In Exports And Supplies Of Chickpeas Of Various
                    Origins That Are Generally Sourced From India.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ajana Impex Pvt. Ltd.
                    Is Counted Amongst One Of The Most Reputed Chickpeas
                    Exporters In The Global Market.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We Procure Chickpeas
                    From Reputed Manufacturers Having Years Of Experience In The
                    Respective Domain
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-info">
                  <h3>Specifications</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Caliber Count </strong>: 75-80, 58-60, 44-46,
                      42-44, 40-42
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Moisture </strong>: 7-10% Max
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Broken & Worm Biten</strong>: 1% Max
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Split & Damage</strong>: 2% Max
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Foreign Matter & Aborted</strong>: 1% Max
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Checkpea">
                        www.ajanaimpex.com/Checkpea
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Checkpea;
