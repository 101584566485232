import React from "react";

function RawCotton() {
  return (
    <>
  <main id="main">


<section id="breadcrumbs" className="breadcrumbs">
  <div className="container">

    <div className="d-flex justify-content-between align-items-center">
      <h2>Raw Cotton</h2>
      <ol>
        <li><a href="/">Home</a></li>
        <li>Products</li>
        <li>Raw Cotton</li>
      </ol>
    </div>

  </div>
</section>

<section id="portfolio-details" className="portfolio-details">
  <div className="container">

    <div className="row gy-4">

      <div className="col-lg-6">
        <div className="portfolio-details-slider swiper">
          <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">

              <img src="/Products/raw-cotton.jpg" alt=""
              style={{
                height: "450px",
                width: "450px;",
                display: "block",
                margin: "auto",
              }}
              className="product_image"
              />
          </div>
        </div>
      </div>

      <div className="col-lg-6">
      <div className="portfolio-description">
          <h2>Raw Cotton</h2>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The cotton is processed with minimum human intervention, where the cotton
                heaping is done by bull tractors and machines to transfer them
                directly to ginning and pressing machines. Since the entire
                cotton produced here is with the least amount of direct human
                intervention, the cotton produced here is of the finest quality
                and less contamination.
          </p>
        </div>
        <div className="portfolio-info">
          <h3>SANKAR - 6</h3>
          <ul>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>STAPLE</strong>: 28.50 to 29 MM</li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>STRENGTH</strong>: 29 to 30 GPT</li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>MIC</strong>: 3.80 to 4.90 NCL</li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>TRASH</strong>: 3%</li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>MOISTURE</strong>: 9.00%</li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Product URL</strong>: <a href="https://www.ajanaimpex.com/Raw-Cotton">www.ajanaimpex.com/Raw-Cotton</a></li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</section>

</main>
</>
  );
}

export default RawCotton;