import React from "react";
import { useNavigate } from "react-router-dom";

const AboutCompany = () => {
  const navigate = useNavigate();
  const data = [
    {
      icon: (
        <div
          className="centered"
          style={{ textAlign: "center", marginTop: "-20px" }}
        >
          <center>
            <img
              src="assets/img/slide/marketing.webp"
              className="d-block"
              alt=""
              height={130}
              width={130}
            />
          </center>
        </div>
      ),
      title: "High Quality Products",
      details:
        "We believe in delivering premium quality products to everyone and will continue to do it.",
    },
    {
      icon: (
        <div
          className="centered"
          style={{ textAlign: "center", marginTop: "-20px" }}
        >
          <center>
            <img
              src="assets/img/slide/ino.webp"
              className="d-block"
              alt=""
              height={130}
              width={145}
            />
          </center>
        </div>
      ),
      title: "INNOVATION",
      details:
        "Our core business is centered around our innovative approaches to animal feed, unlike any other.",
    },
    {
      icon: (
        <div
          className="centered"
          style={{ textAlign: "center", marginTop: "-20px" }}
        >
          <center>
            <img
              src="assets/img/slide/tech.webp"
              className="d-block"
              alt=""
              height={130}
              width={130}
            />
          </center>
        </div>
      ),
      title: "TECHNOLOGY",
      details:
        "Our team is run by technology and technical-minded. We engineer the best solutions for you, always.",
    },
  ];
  return (
    <section className="about-company">
      <div className="container" style={{ marginTop: "-5%" }}>
        <div class="row">
          <div className="col-md-12">
            <div className="centered" style={{ textAlign: "center" }}>
              <h1>
                <b style={{ color: "#5cb874", fontSize: "135%" }}>
                  Ajana Impex Pvt. Ltd.
                </b>
              </h1>
            </div>
          </div>
          <div class="col-md-12">
            <div className="centered" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "120%" }}>
                Our Company Ajana Impex Pvt. Ltd. is the leading manufacturer
                and exporter of feed additives in global market.
              </p>
              <p style={{ fontSize: "120%" }}>
                With more than 12 years of experience, we are the pioneer of
                animal feed products in the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "3%" }}>
        <div className="row">
          {data.map((items) => (
            <>
              <div className="col-lg-4 col-12 mt-lg-0 mt-4">
                <div className="service-box">
                  {items.icon}
                  <h3 style={{ marginTop: "10px", textAlign: "center" }}>
                    {items.title}
                  </h3>
                  <p>{items.details}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div>
        <div
          className="centered"
          style={{ textAlign: "center", marginTop: "4%" }}
        >
          <button
            type="button"
            className="outline-btn"
            onClick={() => navigate("/About")}
          >
            Click here to know more about Ajana Impex&nbsp;&nbsp;
            <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutCompany;
