import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Headera from "./Pages/Headera";
import Header from "./Pages/Header";
import Products from "./Pages/Products";
import Home from "./Pages/Home";
import About from "./Pages/About";
import GlobalExports from "./Pages/GlobalExports";
import Inquiery from "./Pages/Inquiery";
import Contact from "./Pages/Contact";
import NavigationBar from "./Pages/NavigationBar";
import Footer from "./Pages/Footer";
import Slider_A from "./Pages/Slider_A";
import RawCotton from "./Pages/RawCotton";
import WheatFlour from "./Pages/WheatFlour";
import RefindWheatFlour from "./Pages/RefindWheatFlour";
import CuminSeeds from "./Pages/CuminSeeds";
import RedChilli from "./Pages/RedChilli";
import GroundnutPeanut from "./Pages/GroundnutPeanut";
import AnimalFeed from "./Pages/AnimalFeed";
import SesameSeeds from "./Pages/SesameSeeds";
import YellowMaize from "./Pages/YellowMaize";
import IndianGreenMillet from "./Pages/IndianGreenMillet";
import SoyaBean from "./Pages/SoyaBean";
import Rice from "./Pages/Rice";
import CorianderSeeds from "./Pages/CorianderSeeds";
import Checkpea from "./Pages/Checkpea";
import MungBeans from "./Pages/MungBeans";
import MustardSeed from "./Pages/MustardSeed";
import RicePartitionsBasamati from "./Pages/RicePartitionsBasamati";
import RicePartitions from "./Pages/RicePartitions";

function App() {
  return (
    <BrowserRouter>
      <NavigationBar></NavigationBar>
      <Slider_A></Slider_A>
      {/* <Products></Products> */}
      {/* <Headera></Headera> */}
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/About" element={<About></About>} />
        <Route path="/Products" element={<Products></Products>} />
        <Route
          path="/GlobalExports"
          element={<GlobalExports></GlobalExports>}
        />
        <Route path="/Inquiery" element={<Inquiery></Inquiery>} />
        <Route path="/Contact" element={<Contact></Contact>} />
        <Route path="/Raw-Cotton" element={<RawCotton></RawCotton>} />
        <Route path="/Wheat-Flour" element={<WheatFlour></WheatFlour>} />
        <Route
          path="/Refind-Wheat-Flour"
          element={<RefindWheatFlour></RefindWheatFlour>}
        />
        <Route path="/Cumin-Seeds" element={<CuminSeeds></CuminSeeds>} />
        <Route path="/Red-Chilli" element={<RedChilli></RedChilli>} />
        <Route
          path="/Groundnut-or-Peanut"
          element={<GroundnutPeanut></GroundnutPeanut>}
        />
        <Route path="/Animal-Feed" element={<AnimalFeed></AnimalFeed>} />
        <Route path="/Sesame-Seeds" element={<SesameSeeds></SesameSeeds>} />
        <Route path="/Yellow-Maize" element={<YellowMaize></YellowMaize>} />
        <Route
          path="/Indian-Green-Millet"
          element={<IndianGreenMillet></IndianGreenMillet>}
        />
        <Route path="/Soya-Bean" element={<SoyaBean></SoyaBean>} />
        <Route path="/Rice" element={<Rice></Rice>} />
        <Route
          path="/Coriander-Seeds"
          element={<CorianderSeeds></CorianderSeeds>}
        />
        <Route path="/Checkpea" element={<Checkpea></Checkpea>} />
        <Route path="/Mung-beans" element={<MungBeans></MungBeans>} />
        <Route path="/Mustard-Seed" element={<MustardSeed></MustardSeed>} />
        <Route
          path="/Basmati-Rice"
          element={<RicePartitionsBasamati></RicePartitionsBasamati>}
        />
        <Route
          path="/Non-Basmati-Rice"
          element={<RicePartitions></RicePartitions>}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
export default App;
