import React from "react";

function CorianderSeeds() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>CORRIANDER SEEDS</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>CORRIANDER SEEDS</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="Products/cord-img.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Coriander Seeds</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coriander Seeds,
                    Commonly Known As Dhania Or Cilantro. Coriander Seeds Are
                    Used Around The World As A Condiment Or As A Decoration On
                    The Dishes. Ajana Impex Pvt. Ltd. Provides Natural And Fresh
                    Coriander Seeds.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coriander Seeds Are
                    Used In The Process As A Culinary Spice, And It Is Used In
                    Every Kind Of Food For Increasing Taste. Coriander Seeds Are
                    A Good Source Of Dietary Fiber, Iron And Magnesium And It Is
                    Also Used As A Part Of The Various Types Of Drugs.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coriander Seeds Also
                    Has The Medicinal Properties. As It Is A Mild Expectorant,
                    It Is Also Used As An Ingredient Of Medicine And Drugs. It
                    Is Also Used As An Effective Medicine In Treating Diseases
                    Such As Blood Sugar, Digestion, Stomach And Prevent
                    Flatulence, Urinary Tract Infections Etc.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-info">
                  <h3>Coriander Seeds</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Specification </strong>: Coriander Seeds Whole
                      Eagle Quality 98% with 2% Admixture & 8% Moisture &
                      Machine Cleaned.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Specification </strong>: Coriander Seeds Whole
                      Eagle Quality 99.5% with 0.5% Admixture & 8% Moisture &
                      Sortex Cleaned.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Coriander-Seeds">
                        www.ajanaimpex.com/Coriander-Seeds
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CorianderSeeds;
