import React from "react";

function CuminSeeds() {
  return (
    <>
  <main id="main">


<section id="breadcrumbs" className="breadcrumbs">
  <div className="container">

    <div className="d-flex justify-content-between align-items-center">
      <h2>Cumin Seeds</h2>
      <ol>
        <li><a href="/">Home</a></li>
        <li>Products</li>
        <li>Cumin Seeds</li>
      </ol>
    </div>

  </div>
</section>

<section id="portfolio-details" className="portfolio-details">
  <div className="container">

    <div className="row gy-4">

      <div className="col-lg-6">
        <div className="portfolio-details-slider swiper">
          <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">

              <img src="/Products/cummin-seed.jpg" alt=""
              style={{
                height: "450px",
                width: "450px;",
                display: "block",
                margin: "auto",
                marginTop: "25px"
              }}
              className="product_image"
              />
          </div>
        </div>
      </div>

      <div className="col-lg-6">
      <div className="portfolio-description">
          <h2>Cumin Seeds</h2>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We are working
                on the objective to deliver high quality cumin seeds to our
                clients at most favorable prices.we never do compromise on
                quality standards that makes up top cumin seeds exporters,
                manufacturers and suppliers in India.
          </p>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Being prominent Cumin Seeds Exporters, Manufacturers and Suppliers in India, we believe in achieving maximum customer satisfaction through constant efforts and dedication.
          </p>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our cumin seeds are 100 percent fresh and safe in use. They have plenty of healthy benefits too to make you physically and mentally strong.Buy cumin seeds with us at most suitable prices and you can also contact our expert team for further assistance.
          </p>
        </div>
      </div>
      <div className="col-lg-12">
      <div className="portfolio-info">
          <h3>Cumin Seeds</h3>
          <ul>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Our cumin seeds are 100 percent fresh and safe in use.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>It has plenty of healthy benefits to improve your overall health and fitness.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>It is also used to make Indian dishes more interesting.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>It has wonderful anti-septic properties too and gives instant relief against acidity.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Products are checked and verified by quality analyst.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>It is used as a flavoring agent to add distinct flavor to your dishes.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Product URL</strong>: <a href="https://www.ajanaimpex.com/Cumin-Seeds">www.ajanaimpex.com/Cumin-Seeds</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
</main>
</>
  );
}

export default CuminSeeds;