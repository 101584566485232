import React from "react";

function RefindWheatFlour() {
  return (
    <>
  <main id="main">


<section id="breadcrumbs" className="breadcrumbs">
  <div className="container">

    <div className="d-flex justify-content-between align-items-center">
      <h2>Refined Wheat Flour ( Maida Flour )</h2>
      <ol>
        <li><a href="/">Home</a></li>
        <li>Products</li>
        <li>Refined Wheat Flour ( Maida Flour )</li>
      </ol>
    </div>

  </div>
</section>

<section id="portfolio-details" className="portfolio-details">
  <div className="container">

    <div className="row gy-4">

      <div className="col-lg-6">
        <div className="portfolio-details-slider swiper">
          <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">

              <img src="/Products/Refined-Wheat-Flour.jpg" alt=""
              style={{
                height: "450px",
                width: "450px;",
                display: "block",
                margin: "auto",
                marginTop: "25px"
              }}
              className="product_image"
              />
          </div>
        </div>
      </div>

      <div className="col-lg-6">
      <div className="portfolio-description">
          <h2>Refined Wheat Flour ( Maida Flour )</h2>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Maida is basically the Indian term which is used for differentiating 
                the coarse fine powder obtained from wheat flour.
          </p>
        </div>
        <div className="portfolio-info">
          <h3>Refined Wheat Flour ( Maida Flour )</h3>
          <ul>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Refined Wheat Flour is popularly known as Maida Flour.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Fine white maida flour is prepared by milling of wheat grains.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>100% hygienic.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Have a good absorption rate.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Products are checked and verified by quality analyst.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Can be used to prepare an assortment of dishes like Naan, Parothas, Cakes and biscuits.</strong></li>
            <li><i
                    className="bx bxs-right-arrow-circle bx-burst-hover"
                    style={{ fontSize: "15px" }}
                  ></i>{" "} <strong>Product URL</strong>: <a href="https://www.ajanaimpex.com/Refind-Wheat-Flour">www.ajanaimpex.com/Refind-Wheat-Flour</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
</main>
</>
  );
}

export default RefindWheatFlour;