import React from "react";

function SoyaBean() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Soya Bean</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Soya Bean</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/Soya-Bean.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Soya Bean</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    With our decade of experience, we are catering to the varied requirements of our clients by introducing quality grade of Soy bean Seed. Our skilled professionals make us of ultra modern techniques and machines to process this grade. Provided grade is widely used for making oil and products like tofu, flour, milk etc. In order to ensure quality, this is strictly tested against various quality parameters. Moreover, we provide this grade in various packaging options and at affordable prices.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Soya bean is the world's largest source of vegetables. The natural taste of soyabean and soybean oil enhances the flavour of food, which are rich in protein and are regularly consuming cereal internationally. Further, we ensure that our products are 100% pure and natural suitable to be used in variety of cooking.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Soya beans are oil seeds used worldwide for various uses mostly for making oil and products like Tofu, Flour, Milk etc. These are processed using high techniques of grading and sorting to use it directly for consumption. procured from trustworthy farmer. our range is widely consumed by large number of global clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
              <div className="portfolio-info">
                  <h3>Soya Bean</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Features </strong>: Highly Nutritious With Excellent Taste.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product Details </strong>: Natural With Nutrition Facts.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Protine & Meal </strong>: The seed contains 17 percent oil and 63 percent meal, 50 percent of which is protein.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Soya-Bean">
                        www.ajanaimpex.com/Soya-Bean
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default SoyaBean;