import React from "react";
import { useNavigate } from "react-router-dom";

function Products() {
  const navigate = useNavigate();
  const data = [
    // {
    //   img: "/Products/raw-cotton.jpg",
    //   title: "Raw cotton",
    //   navigate: "/Raw-Cotton",
    // },
    {
      img: "/Products/Groundnut-or-Penut.jpg",
      title: "Groundnuts or peanuts",
      navigate: "/Groundnut-or-Peanut",
    },
    {
      img: "/Products/rice.jpg",
      title: "Rice",
      navigate: "/Rice",
    },
    {
      img: "/Products/Sesame-Seeds.jpg",
      title: "Sesame Seeds",
      navigate: "/Sesame-Seeds",
    },
    {
      img: "/Products/cummin-seed.jpg",
      title: "Cumin seed",
      navigate: "/Cumin-Seeds",
    },
    {
      img: "/Products/Coriander-Seeds.jfif",
      title: "Coriander seeds",
      navigate: "/Coriander-Seeds",
    },
    {
      img: "/Products/Yellow-Maize.jpg",
      title: "Yellow Maize (Corn)",
      navigate: "/Yellow-Maize",
    },
    {
      img: "/Products/Indian-Green-Millet.jpg",
      title: "Indian-Green-Millet",
      navigate: "/Indian-Green-Millet",
    },
    {
      img: "/Products/Checkpea.jfif",
      title: "Checkpea",
      navigate: "/Checkpea",
    },
    {
      img: "/Products/mug-bean.jfif",
      title: "Mung beans",
      navigate: "/Mung-beans",
    },
    {
      img: "/Products/Mustard-Seeds.jpg",
      title: "Mustard Seed",
      navigate: "/Mustard-Seed",
    },
    {
      img: "/Products/Soya-Bean.jpg",
      title: "Soya Bean",
      navigate: "/Soya-Bean",
    },
  ];
  return (
    <section className="our-products">
      <div className="container">
        <h3 className="main-title" style={{ marginTop: "-20" }}>
          Our Products
        </h3>
        <div className="row">
          {data.map((items) => (
            <div className="col-xl-4 col-md-6">
              <a
                onClick={() => navigate(items.navigate)}
                className="position-relative single-product"
              >
                <div className="product-img">
                  <img src={items.img} alt="" />
                </div>
                <div className="product-name" style={{ textAlign: "center" }}>
                  {/* <p style={{ textAlign: "center" }}></p> */}
                  <h5>{items.title}</h5>
                  {/* <p>Brand: {items.brand}</p> */}
                </div>
              </a>
            </div>
          ))}
        </div>
        {/* <div className="text-center">
          <button type="button" className="outline-btn">
            View All
          </button>
        </div> */}
      </div>
    </section>
  );
}

export default Products;
