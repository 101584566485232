import React from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "./NavigationBar";

function Rice() {
  const navigate = useNavigate();
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Rice</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Rice</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div
                className="col-lg-6"
                onClick={() => navigate("/Basmati-Rice")}
              >
                <div className="portfolio-info">
                  <h3>Basmati Rice</h3>
                  <div className="portfolio-details-slider swiper">
                    <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                      <img
                        src="/assets/img/slide/basmati-rice.avif"
                        alt=""
                        style={{
                          height: "300px",
                          width: "300px;",
                          display: "block",
                          margin: "auto",
                          marginTop: "15px",
                        }}
                        className="product_image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                onClick={() => navigate("/Non-Basmati-Rice")}
              >
                <div className="portfolio-info">
                  <h3>Non Basmati Rice</h3>
                  <div className="portfolio-details-slider swiper">
                    <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                      <img
                        src="/assets/img/slide/non-basmati.avif"
                        alt=""
                        style={{
                          height: "300px",
                          width: "300px;",
                          display: "block",
                          margin: "auto",
                          marginTop: "15px",
                        }}
                        className="product_image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Rice;
