import React from "react";

function RicePartitionsBasamati() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Basamati Rice</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Basamati Rice</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/1121-basmati-rice.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>1121 Basmati Rice – Parboiled / Golden / Steamed.</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1121 Long Grain Basmati
                    Rice of Indian origin is characterized by its length which
                    ranges from 8.15 mm to 8.35mm
                  </p>
                </div>
                <div className="portfolio-info">
                  <h3>1121 Basmati Rice</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        1121 Sella (Parboiled) Basmati Rice variety is extremely
                        popular amongst the world because of its varieties such
                        as 100% purity, long grain size, aromatic fragrance and
                        non-sticky after cooking..
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Rice">
                        www.ajanaimpex.com/Rice
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/1509-Basmati-Rice.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>1509 Basmati Rice – Parboiled / Golden / Steamed.</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our valuable clients can
                    avail from us a delectable range of Long Grain Basmati Rice
                    that is available in various packaging options.
                  </p>
                </div>
                <div className="portfolio-info">
                  <h3>1509 Basmati Rice – Parboiled / Golden / Steamed.</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        This rice is hugely consumed by our clients across the
                        world, owing to its distinct aroma and nutrition.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        The offered rice is grown under the suitable environment
                        and is passed through different milling processes.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        We ensure our clients that our rice is hygienically
                        processed and packed, which ensure its safe consumption
                        and high quality.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Rice">
                        www.ajanaimpex.com/Rice
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/sugandha-rice.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Sugandha Rice – Parboiled / Golden / Steamed.</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sugandha Rice offered by
                    Ajana Impex Pvt. Ltd. is one of the finest quality of
                    Basmati Rice available in India.
                  </p>
                </div>
                <div className="portfolio-info">
                  <h3>Sugandha Rice – Parboiled / Golden / Steamed.</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        Sugandha Long Grain Rice is basically a raw steam
                        parboiled white with golden sella.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        Sugandha rice produces exotic aroma while cooking and
                        has mouth watering taste.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        The nutritional value of sugandha rice is also very
                        high, thus, their demand in households and restaurants
                        always at the peak.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        Excellent for biryani and pulao, the sugandha rice
                        offered by us can not be found anywhere else in the
                        market.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Rice">
                        www.ajanaimpex.com/Rice
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/pusa-basmati-rice.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>
                    Pusa Basmati Rice – Parboiled / Golden / Steamed / Brown
                  </h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pusa Basmati Rice is a
                    particular hybrid variety of Basmati Rice.
                  </p>
                </div>
                <div className="portfolio-info">
                  <h3>
                    Pusa Basmati Rice – Parboiled / Golden / Steamed / Brown.
                  </h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        The implementation of the latest technology in the
                        cultivation of the Pusa Basmati Raw Rice makes it much
                        more improved in quality.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        The rare flavor and aroma of the Pusa Basmati Steamed
                        Rice is the key to its popularity in the national and
                        international markets.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        We stand among the foremost Suppliers of Pusa Basmati
                        Rice all over India and abroad.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Rice">
                        www.ajanaimpex.com/Rice
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default RicePartitionsBasamati;
