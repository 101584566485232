import React from "react";

function GlobalExports() {
  return (
    <>
      {/* ======= Products Section ======= */}
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>Global Exports</h2>
          </div>
          <div className="row" style={{ marginTop: "1%" }}>
            <div className="col-lg-12 order-1 order-lg-1 img-hover-zoom--brightness">
              <img
                src="assets/img/product/global-1.jpg"
                style={{
                  height: "500px",
                  width: "80%px;",
                  display: "block",
                  margin: "auto",
                }}
                className="global_image"
              />
            </div>
            <div
              className="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h3 className="hover-1">
                <i
                  className="bi bi-geo-fill"
                  style={{ fontSize: "25px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Our Major Markets
              </h3>
              <p></p>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;USA
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;UK
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Australia
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Canada
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;UAE
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Russia
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Indonesia
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;China
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Malaysia
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;France
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Poland
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Spain
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Singapore
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Germany
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Korea
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;South Africa
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Turkey
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Egypt
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Bangladesh
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Vietnam
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Taipei
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Thailand
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Philippines
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Belgium
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Sri Lanka
              </h4>
            </div>
            <div
              className="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ marginTop: "1%" }}
            >
              <h4 className="hover-1">
                <i
                  className="bi bi-geo-alt-fill"
                  style={{ fontSize: "20px", color: "#5cb874" }}
                ></i>
                &nbsp;&nbsp;Italy
              </h4>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="cta" className="cta">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 text-center text-lg-start">
            <h3>Welcome To AJANA IMPEX PRIVATE LIMITED.</h3>
          </div>
          <div className="col-lg-3 cta-btn-container text-center">
            <a className="cta-btn" href="#">Call To Action</a>
          </div>
        </div>

      </div>
    </section> */}
    </>
  );
}
export default GlobalExports;
