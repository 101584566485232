import React from "react";

function YellowMaize() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Yellow Maize (Corn)</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Yellow Maize (Corn)</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/Yellow-Maize.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Yellow Maize (Corn)</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    We export a healthy and nutritious range of yellow maize. It is well-dried and chemical free. It is a yellowish colour grain and is used as food stuff for human being as well as birds and animals food. Maize is a staple crop in some countries. It is free from bacterial and fungal diseases. We offer them in both raw as well as sorted type. These are used in making fast foods, soups and dietary foods. They are known for their high nutritional value and healthy attributes. Clients can choose from various grades. The product is processed in highly hygienic conditions.Maize is commonly known as corn in some countries and the largest cash crop cultivated. It is the third largest grown crop in the world. The corn is grown under hygienic conditions in Indian fields
                  </p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  We supply only Indian variety of yellow maize, which is free from any bacterial and fungal diseases.The Indian yellow maize is used in various food industries and other industries. It is used for human consumption and for animal feed. The corn has great demand across the world. The buyers are welcome to stay in India during the shipment and take documents like invoice and bill of lading by hand or we can send it via bank. Our range of maize is available in yellow type and white type as per the needs of the customers
                  </p>
                </div>
                
              </div>
              <div className="col-lg-12">
              <div className="portfolio-info">
                  <h3>Yellow Maize (Corn)</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Features </strong>: Pure, Fresh, Delectable taste, Unique fragrance & High nutritional content                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Rich In </strong>: Iron, proteins, potassium, magnesium, health fats & carbohydrates
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Uses </strong>: Food or human consumption, animal consumption, manufactured in oil mill, flour, biodiesel , energy, & much more.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Yellow-Maize">
                        www.ajanaimpex.com/Yellow-Maize
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default YellowMaize;