import React from "react";

function IndianGreenMillet() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Indian Green Millet</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Indian Green Millet</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/Indian-Green-Millet.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Indian Green Millet</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ajana Impex exports and produces premium Indian Green Millet (Hara Bajra) from India to an excessive number of international nations. 
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The finest grade of Millet/Bajra is free from any foul smell and highly suitable for poultry feeding as well. We export product with premium packing that saves its nutritional values for longer period of time. It is highly acknowledged by our valuable clients as it is rich in calcium, protein and iron.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; It helps to reduce bad cholesterol level in our body. We provide fresh crops of Bold Green Millet from Gujarat and Rajasthan in India. There is different quality for Human Consumption which is Green and Bold & for Animal feed, there Green Grain with brown spots. We are committed to provide at best price and best quality to our client.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="portfolio-info">
                  <h3>Uses Of Indian Green Millet</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Indian Green Millet is used for making high energy food.</strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Pearl Millet Seeds are used to feed poultry, birds and fish.</strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Indian Green Millet is used for animal feed/cattle feed and Bird feed.</strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Indian-Green-Millet">
                        www.ajanaimpex.com/Indian-Green-Millet
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default IndianGreenMillet;