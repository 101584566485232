import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Products from "./Products";

function NavigationBar() {
  const navigate = useNavigate();

  const [activeHome, setactiveHome] = useState(false);
  const [activeAbout, setactiveAbout] = useState(false);
  const [activeProducts, setactiveProducts] = useState(false);
  const [activeSubProducts, setactiveSubProducts] = useState(false);
  const [activeGlobalExports, setactiveGlobalExports] = useState(false);
  const [activeInquiery, setactiveInquiery] = useState(false);
  const [activeContact, setactiveContact] = useState(false);
  const [headerMenu, setheaderMenu] = useState(false);

  let activeMenuClassHome = activeHome ? " active" : null;
  let activeMenuClassAbout = activeAbout ? " active" : null;
  let activeMenuClassProducts = activeProducts ? " active" : null;
  let activeMenuClassSubProducts = activeSubProducts
    ? " dropdown-active"
    : null;
  let activeMenuClassExports = activeGlobalExports ? " active" : null;
  let activeMenuClassInquiery = activeInquiery ? " active" : null;
  let activeMenuClassContact = activeContact ? " active" : null;
  let headerIconClassCheck = headerMenu ? " bi-x" : " bi-list";
  let navbarClassCheck = headerMenu ? " navbar-mobile" : "";
  var mobile_menu_flag: Boolean = false;
  var products_sub: Boolean = false;

  function handlesetActiveMenu(
    home: boolean,
    about: boolean,
    products: boolean,
    exports: boolean,
    inquiery: boolean,
    contact: boolean
  ) {
    setactiveHome((activeHome) => (activeHome = home));
    setactiveAbout((activeAbout) => (activeAbout = about));
    setactiveProducts((activeProducts) => (activeProducts = products));
    setactiveSubProducts(
      (activeMenuClassProducts) => (activeMenuClassProducts = products)
    );
    setactiveGlobalExports(
      (activeGlobalExports) => (activeGlobalExports = exports)
    );
    setactiveInquiery((activeInquiery) => (activeInquiery = inquiery));
    setactiveContact((activeContact) => (activeContact = contact));
  }

  var lastCharacter: String = "";

  const handleActiveMenuClick = (menu_nanme: String) => {
    var lastCharacter = menu_nanme;

    if (
      lastCharacter === "Home" ||
      lastCharacter === "home" ||
      lastCharacter === ""
    ) {
      handlesetActiveMenu(true, false, false, false, false, false);
      products_sub = true;
      navigate("/");
    }

    if (lastCharacter === "About" || lastCharacter === "about") {
      handlesetActiveMenu(false, true, false, false, false, false);
      products_sub = true;
      navigate("/About");
    }

    if (lastCharacter === "Products" || lastCharacter === "products") {
      // products_sub = false;
      // if (products_sub === true) {
      //   handlesetActiveMenu(false, false, false, false, false, false);
      // } else {
      //   handlesetActiveMenu(false, false, true, false, false, false);
      // }
      handlesetActiveMenu(false, true, false, false, false, false);
      products_sub = true;
      navigate("/Products");
    }

    if (
      lastCharacter === "GlobalExports" ||
      lastCharacter === "globalExports"
    ) {
      products_sub = true;
      handlesetActiveMenu(false, false, false, true, false, false);
      navigate("/globalExports");
    }

    if (lastCharacter === "Inquiery" || lastCharacter === "inquiery") {
      products_sub = true;
      handlesetActiveMenu(false, false, false, false, true, false);
      navigate("/Inquiery");
    }

    if (lastCharacter === "Contact" || lastCharacter === "contact") {
      products_sub = true;
      handlesetActiveMenu(false, false, false, false, false, true);
      navigate("/Contact");
    }

    if (lastCharacter === "Raw-Cotton") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Raw-Cotton");
    }
    if (lastCharacter === "Animal-Feed") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Animal-Feed");
    }
    if (lastCharacter === "Sesame-Seeds") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Sesame-Seeds");
    }
    if (lastCharacter === "Animal-Feed") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Animal-Feed");
    }
    if (lastCharacter === "Cumin-Seeds") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Cumin-Seeds");
    }
    if (lastCharacter === "Wheat-Flour") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Wheat-Flour");
    }
    if (lastCharacter === "Refind-Wheat-Flour") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Refind-Wheat-Flour");
    }
    if (lastCharacter === "Groundnut-or-Peanut") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Groundnut-or-Peanut");
    }
    if (lastCharacter === "Red-Chilli") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Red-Chilli");
    }
    if (lastCharacter === "Yellow-Maize") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Yellow-Maize");
    }
    if (lastCharacter === "Millet") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Millet");
    }
    if (lastCharacter === "Soya-Bean") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Soya-Bean");
    }
    if (lastCharacter === "Indian-Green-Millet") {
      products_sub = true;
      handlesetActiveMenu(false, false, true, false, false, false);
      navigate("/Indian-Green-Millet");
    }
    if (products_sub === true) {
      setheaderMenu(false);
    }
  };
  function handleMenubtnClick() {
    mobile_menu_flag = true;
    setheaderMenu((headerMenu) => !headerMenu);
  }

  useEffect(() => {
    const url = window.location.href.split("/");
    lastCharacter = url[url.length - 1];
    handleActiveMenuClick(lastCharacter);
  }, []);

  return (
    <>
      {/* ======= Top Bar ======= */}
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            {/* <a href="mailto:info@ajanaimpex.com" className="email_info"> <i className="bi bi-envelope-fill"></i>info@ajanaimpex.com</a>
          <a href="tel:+91 95125 09292"> <i className="bi bi-phone-fill phone-icon"></i>+91 95125 09292</a>  */}
          </div>
          <div className="social-links d-none d-md-block">
            <div className="contact-info d-flex align-items-center">
              <a href="mailto:info@ajanaimpex.com" className="email_info">
                {" "}
                <i className="bi bi-envelope-fill"></i>info@ajanaimpex.com
              </a>
              <a href="tel:+91 95125 09292">
                {" "}
                <i className="bi bi-phone-fill phone-icon"></i>+91 95125 09292
              </a>
            </div>
            {/* <a href="https://api.whatsapp.com/send?phone=+919512509292&text=AjanaImpex" target="_blank" className="twitter"><i className="bi bi-whatsapp"></i></a>
          <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
          <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
          <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a> */}
          </div>
        </div>
      </section>

      {/* ======= Header ======= */}
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center">
          {/* <h1 className="logo me-auto"><a href="index.html">Ajana Impex</a></h1> */}
          <a
            className="logo me-auto"
            onClick={() => handleActiveMenuClick("Home")}
            style={{ marginBottom: "1%" }}
          >
            <img
              src="/assets/img/slide/Logo.png"
              alt=""
              className="change_logo"
            />
          </a>
          {/* navbar-mobile 
            bi-list
        */}
          <nav id="navbar" className={`navbar${navbarClassCheck}`}>
            {/* fixed-top */}
            <ul>
              <li>
                <a
                  className={`nav-link ${activeMenuClassHome}`}
                  onClick={() => handleActiveMenuClick("")}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className={`nav-link ${activeMenuClassAbout}`}
                  onClick={() => handleActiveMenuClick("About")}
                >
                  About
                </a>
              </li>
              {/* <li><a className={`nav-link ${activeMenuClassAbout}`} onClick={() => handleActiveMenuClick('About')}>About</a></li> */}
              {/* <li><a className={`nav-link ${activeMenuClassProducts}`} onClick={() => handleActiveMenuClick('Products')}>Products</a></li> */}
              <li>
                <a
                  className={`nav-link ${activeMenuClassHome}`}
                  onClick={() => handleActiveMenuClick("Products")}
                >
                  Products
                </a>
              </li>
              {/* <li className={`dropdown ${activeMenuClassAbout}`}>
                <a onClick={() => handleActiveMenuClick("Products")}>
                  <span>Products</span> <i className="bi bi-chevron-down"></i>
                </a>
                <ul className={`${activeMenuClassSubProducts}`}>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Raw-Cotton")}
                    >
                      Raw Cotton
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Animal-Feed")}
                    >
                      Animal Feed
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Sesame-Seeds")}
                    >
                      Sesame Seeds
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Cumin-Seeds")}
                    >
                      Cumin Seeds
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Wheat-Flour")}
                    >
                      Wheat Flour
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() =>
                        handleActiveMenuClick("Refind-Wheat-Flour")
                      }
                    >
                      Refind Wheat Flour
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() =>
                        handleActiveMenuClick("Groundnut-or-Peanut")
                      }
                    >
                      Groundnut or Peanut
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Red-Chilli")}
                    >
                      Red Chilli
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Yellow-Maize")}
                    >
                      Yellow Maize (Corn)
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() =>
                        handleActiveMenuClick("Indian-Green-Millet")
                      }
                    >
                      Indian Green Millet
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => handleActiveMenuClick("Soya-Bean")}
                    >
                      Soya Bean
                    </a>
                  </li>
                  {/* <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                  <ul>
                    <li><a href="#">Deep Drop Down 1</a></li>
                    <li><a href="#">Deep Drop Down 2</a></li>
                    <li><a href="#">Deep Drop Down 3</a></li>
                    <li><a href="#">Deep Drop Down 4</a></li>
                    <li><a href="#">Deep Drop Down 5</a></li>
                  </ul>
                </li>
                </ul>
              </li> */}
              <li>
                <a
                  className={`nav-link ${activeMenuClassExports}`}
                  onClick={() => handleActiveMenuClick("GlobalExports")}
                >
                  Global Exports
                </a>
              </li>
              <li>
                <a
                  className={`nav-link ${activeMenuClassContact}`}
                  onClick={() => handleActiveMenuClick("Contact")}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  className={`getstarted ${activeMenuClassInquiery}`}
                  onClick={() => handleActiveMenuClick("Inquiery")}
                >
                  Quick Inquiery
                </a>
              </li>
              {/* <i className="bi bi-telephone-fill inq-call-icon" style={{"fontSize":"18px"}}></i>&nbsp;  */}
            </ul>
            <i
              className={`bi mobile-nav-toggle${headerIconClassCheck}`}
              onClick={handleMenubtnClick}
            ></i>
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
    </>
  );
}
export default NavigationBar;
