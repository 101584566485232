import React from "react";
import { useNavigate } from "react-router-dom";

const ProductsGallary = () => {
  const navigate = useNavigate();
  return (
    <section className="about-company">
      <div className="container" style={{ marginTop: "-5%" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="centered" style={{ textAlign: "center" }}>
              <h1>
                <b style={{ color: "#5cb874", fontSize: "135%" }}>
                  Our Products & Services
                </b>
              </h1>
            </div>
          </div>
          <div className="centered" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "125%" }}>
              At Ajana Impex Pvt. Ltd. we mindfully and carefully research,
              test,quality and select the best feed additives for your precious
              livestock. We offer solutions ranging from farm feed to aqua feed.
            </p>
          </div>
          <div>
            <div className="container_abc">
              <div id="carousel_abc">
                <figure>
                  <img
                    src="/assets/img/slide/sing.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/seesm.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/basmati.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/drasya.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/pulse.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/sing.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/seesm.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/basmati.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
                <figure>
                  <img
                    src="/assets/img/slide/pulse.avif"
                    alt=""
                    className="d-block w-100 h-100"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div className="gallery_button_margin">
            <div className="centered" style={{ textAlign: "center" }}>
              <button
                type="button"
                className="outline-btn"
                onClick={() => navigate("/Products")}
              >
                View all products &nbsp;&nbsp;
                <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsGallary;
