import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

function Slider_A() {
  return (
    <>
      <Slider autoplay={800}>
        <div key={0} style={{ height: "500px" }}>
          <img
            className="d-block w-100 h-75"
            src="/assets/img/slide/sing.avif"
            alt="Third slide"
            height={500}
          />
        </div>
        <div key={1} style={{ height: "500px" }}>
          <img
            className="d-block w-100 h-75"
            src="/assets/img/slide/seesm.avif"
            alt="Third slide"
            height={500}
          />
        </div>
        <div key={3} style={{ height: "500px" }}>
          <img
            className="d-block w-100 h-75"
            src="/assets/img/slide/drasya.avif"
            alt="Third slide"
            height={500}
          />
        </div>
        <div key={4} style={{ height: "500px" }}>
          <img
            className="d-block w-100 h-75"
            src="/assets/img/slide/basmati.avif"
            alt="Third slide"
            height={500}
          />
        </div>
        <div key={5} style={{ height: "500px" }}>
          <img
            className="d-block w-100 h-75"
            src="/assets/img/slide/pulse.avif"
            alt="Third slide"
            height={500}
          />
        </div>
      </Slider>
    </>
  );
}

export default Slider_A;
