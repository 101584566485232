import React from "react";

function AnimalFeed() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Animal Feed</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Animal Feed</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/oil-cake.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Cottonseed Oil Cake</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We offer cotton seed oil
                    cakes that are the solid matter that remains once oil has
                    been extracted and pressed from cotton seeds these cotton
                    seed cakes are widely used in the industry for feeding
                    cattle.
                  </p>
                </div>
                <div className="portfolio-info">
                  <h3>Cottonseed Oil Cake</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Protein </strong>: 20% - 22%
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Oil Content </strong>: 7% - 8%
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Fiber </strong>: 12% - 14%
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Sand & Silica </strong>: 2.5% Max
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Packing Details </strong>: 50Kg PP Bags in
                      Containers
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Animal-Feed">
                        www.ajanaimpex.com/Animal-Feed
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/groud-nut-oil.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Groundnut Oil Cake</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;India accounts to be one
                    of the largest groundnut producing nations. We at our use
                    high quality nutritious groundnut for the production of our
                    extraction cake.Groundnut oil cake contains high nutrition
                    and oil content, used to garden and animal feeds.
                  </p>
                </div>
                <div className="portfolio-info">
                  <h3>Groundnut Oil Cake</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        Groundnut Oil Cake is made by extracting the oil
                        completely from the groundnut seeds.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        The left over is then processed to produce high quality
                        Groundnut Oil Cake.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>
                        The product is highly nutritious and widely used in as
                        cattle feed. All our products undergo strict quality
                        checks to ensure purity and freshness.
                      </strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Animal-Feed">
                        www.ajanaimpex.com/Animal-Feed
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="/Products/Maize-Grits.jpg"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Maize Grit</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Maize Cattle Feed
                    has many food (grain, flour, syrup, oil...) and non-food
                    usages (cosmetics, adhesives, paints, varnishes). Maize
                    starch and oil are also major products . Maize Cattle Feed
                    is fed as dry maize grain (less than 15% moisture) or
                    high-moisture maize grain (22 to 28% moisture). When maize
                    is dried, it should not be heated over 150°C, since this
                    reduces nutritive value and acceptability to pigs.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The maize grain is a
                    major feed grain and a_ standard component of livestock
                    diets where it is used as a source of energy. Other grains
                    are typically compared to maize when their nutritional value
                    is estimated.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Maize Cattle Feed
                    supplements are processed using excellent grade maize
                    (Makkai) with the aid of pioneering processing methods by
                    our deft professionals. The offered supplements are widely
                    used for feeding cattle in order to improve their health.
                    The offered Maize Cattle Feed Supplements can be bought from
                    us at marginal prices.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="portfolio-info">
                  <h3>Benifits of Maize Grit</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Rich in protein.</strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Long shelf life.</strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Easy to digest.</strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Free from impurities.</strong>
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Animal-Feed">
                        www.ajanaimpex.com/Animal-Feed
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AnimalFeed;
