import React from "react";

function MungBeans() {
  return (
    <>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Mung Beans</h2>
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Products</li>
                <li>Mung Beans</li>
              </ol>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center img-hover-zoom--brightness">
                    <img
                      src="Products/mug-bean.jfif"
                      alt=""
                      style={{
                        height: "450px",
                        width: "450px;",
                        display: "block",
                        margin: "auto",
                        marginTop: "30px",
                      }}
                      className="product_image"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="portfolio-description">
                  <h2>Mung beans</h2>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ajana Pvt. Ltd. a
                    Rajkot (Gujarat, India) based green mung beans suppliers in
                    India, primarily committed to giving an excellent quality
                    Green Mung Bean, which is backed by extensive industry
                    expertise. Our beans are well-processed utilizing advanced
                    processing techniques and high-grade machinery in the most
                    sanitary and clean environment possible.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our devoted clientele
                    appreciates the exceptional freshness and nutritious content
                    of the beans we provide. Furthermore, our clients may obtain
                    these beans at market-leading costs.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mung bean is abundant
                    in nutrients and is said to help with a variety of diseases.
                    Following a thorough market investigation, we produce these
                    items from the being reliable and well-known fresh green
                    mung beans supplier. Furthermore, we provide these items at
                    competitive prices and deliver them within the specified
                    time limit.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-info">
                  <h3>Product Details</h3>
                  <ul>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Broken Kernels </strong>: 1-2% Max.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Place Of Origin </strong>: India
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Weevilled </strong>: 0.3% Max.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Damaged Kernels </strong>: 1-3% Max.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Discoloured Kernels </strong>: 2-3% Max.
                    </li>
                    <li>
                      <i
                        className="bx bxs-right-arrow-circle bx-burst-hover"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      <strong>Product URL</strong>:{" "}
                      <a href="https://www.ajanaimpex.com/Mung-beans">
                        www.ajanaimpex.com/Mung-beans
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MungBeans;
